<template>
  <iq-card class="mt-5 py-3 px-3">
    <h3 class="text-primary mb-2 border-bottom">{{$t('payment.orderInfo')}}</h3>
    <div class="d-flex align-items-center">
      <div class="payment-store-img">
        <img :src="items.store_info.logo" alt="img">
      </div>
      <h4 class="text-primary mr-2">{{items.store_info.name}}</h4>
    </div>
    <h4 class="text-primary mt-3 mb-2 font-weight-bold border-bottom">{{ $t('main.products') }}</h4>
    <div class="d-flex align-items-center justify-content-between border-bottom py-3" v-for="(product , key) in items.store_products" :key="key">
      <div class="payment-store-img">
        <img :src="product.featured_image" alt="img">
      </div>
      <div class="d-flex flex-column">
        <h6 class="m-0 font-weight-bold text-primary font-size-19">{{ product.title}}</h6>
        <p class="m-0 text-primary font-size-18">{{ product.price }}<i class="las la-times text-primary font-weight-bold px-1"></i>
          {{ product.quantityInCart }}</p>
      </div>
      <span class="test font-size-18 font-weight-bold text-muted">{{ (product.price * product.quantityInCart) }} {{$t('endUser.rs')}}</span>
      <b-button
          variant="white"
          class="action-cart-button text-danger mr-2"
          @click="$emit('deleteProductFromReset', product)"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="25.98" height="25.98" viewBox="0 0 25.98 25.98">
          <g id="Group_3438" data-name="Group 3438" transform="translate(-46.01 -283.617)">
            <g id="Rectangle_1435" data-name="Rectangle 1435" transform="translate(46.01 283.617)" fill="#fff" stroke="#9b9b9b" stroke-width="0.3">
              <rect width="25.98" height="25.98" rx="3" stroke="none"/>
              <rect x="0.15" y="0.15" width="25.68" height="25.68" rx="2.85" fill="none"/>
            </g>
            <path id="da086273b974cb595139babd4da17772" d="M15.058,7.617l-.194,5.862a1.931,1.931,0,0,1-1.937,1.867H9.248A1.931,1.931,0,0,1,7.312,13.48L7.118,7.617a.484.484,0,0,1,.968-.032l.194,5.863a.968.968,0,0,0,.968.931h3.679a.968.968,0,0,0,.968-.933l.194-5.861a.484.484,0,0,1,.968.032ZM15.7,5.666a.484.484,0,0,1-.484.484H6.962a.484.484,0,1,1,0-.968h1.5a.618.618,0,0,0,.616-.556,1.448,1.448,0,0,1,1.445-1.3h1.128a1.448,1.448,0,0,1,1.445,1.3.618.618,0,0,0,.616.556h1.5a.484.484,0,0,1,.484.484ZM9.92,5.182h2.337a1.6,1.6,0,0,1-.123-.457.484.484,0,0,0-.481-.436H10.524a.484.484,0,0,0-.481.436,1.6,1.6,0,0,1-.124.457Zm.488,7.335V8.395a.484.484,0,1,0-.968,0v4.124a.484.484,0,1,0,.968,0Zm2.331,0V8.395a.484.484,0,1,0-.968,0v4.124a.484.484,0,1,0,.968,0Z" transform="translate(47.912 287.273)" fill="#9b9b9b"/>
          </g>
        </svg>

      </b-button>
    </div>
    <div class="d-flex align-items-center justify-content-between border-bottom">
      <h5 class="text-secondary py-3">{{ $t('payment.sum') }}</h5>
      <span class="font-size-16">{{ getTotalWithoutTax }} {{$t('endUser.rs')}}</span>
    </div>
    <div class="d-flex align-items-center justify-content-between border-bottom">
      <h5 class="text-secondary py-3">{{$t('payment.transportation')}}</h5>
      <span class="font-size-16">0 {{$t('endUser.rs')}}</span>
    </div>
    <div class="d-flex align-items-center justify-content-between border-bottom">
      <h5 class="text-secondary py-3">{{$t('payment.tax')}}</h5>
      <span class="font-size-16">{{ calculateTax }} {{$t('endUser.rs')}}</span>
    </div>
    <div class="d-flex align-items-center justify-content-between border-bottom" v-if="discountValue">
      <h5 class="text-secondary py-3">{{$t('payment.discountCode')}} ({{discountCode}})</h5>
      <span class="font-size-16">-{{ discountValue }} {{$t('endUser.rs')}}</span>
    </div>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="text-secondary py-3 font-weight-bold font-size-18">{{ $t('marketplace.total') }}</h5>
      <div>
        <span class="font-weight-bold text-danger font-size-14 text-decoration-line-through ml-2" v-if="discountValue">{{ getTotalWithoutTax +  calculateTax }} {{$t('endUser.rs')}}</span>
        <span class="font-weight-bold font-size-18">{{ (getTotalWithoutTax +  calculateTax) - discountValue }} {{$t('endUser.rs')}}</span>
      </div>
    </div>
  </iq-card>
</template>

<script>
import { core } from '@/config/pluginInit'
import marketplace from '@/modules/servicePages/marketplace/services/marketplace'
export default {
  name: 'paymentCard',
  props: ['items', 'discountValue', 'discountCode'],
  created () {
    this.getProducts()
  },
  computed: {
    getTotalWithoutTax () {
      return this.items.store_products.reduce(
        (accumulator, currentValue) => accumulator + currentValue.price_info.price_without_tax * currentValue.quantityInCart,
        0
      )
    },
    calculateTax () {
      return this.items.store_products.reduce(
        (accumulator, currentValue) => accumulator + currentValue.price_info.tax * currentValue.quantityInCart,
        0
      )
    }
  },
  methods: {
    getProducts () {
      this.loadingStore = true
      marketplace.getProductCart().then(res => {
        /*  eslint-disable  */
        let carts = []
        res.data.data.forEach(product => {
          if (product.product) {
            const getStoreId = carts.findIndex(store => store.store_info.id === product.store.id)
            if (getStoreId > -1) {
              carts[getStoreId].store_products.push({
                ...product.product,
                store_id: product.store.id,
                quantityInCart: Number(product.qty),
                totalProductPrice: Number(product.qty) * product.product.price_info.final_price,
              })
              carts[getStoreId].store_info = {
                ...carts[getStoreId].store_info,
                totalFees : Number(carts[getStoreId].store_info.totalFees) + (product.product.price_info.final_price  * Number(product.qty))
              }
            } else {
              carts.push({
                store_info: {
                  ...product.store,
                  totalFees : (product.product.price_info.final_price  * Number(product.qty))
                },
                store_products: [{
                  ...product.product,
                  store_id: product.store.id,
                  quantityInCart:  Number(product.qty),
                  totalProductPrice:  Number(product.qty) * product.product.price_info.final_price,
                }]
              })
            }
          }
        })
        this.$store.dispatch('cart/updateCart', carts)
      }).finally(() => {
        this.loadingStore = false
      })
    }
  },
  mounted () {
    core.index()
  }
}
</script>

<style>
/*@media (max-width: 840px) {*/
/*  h3{*/
/*    font-size: 20px !important;*/
/*  }*/
/*  h4{*/
/*    font-size: 16px !important;*/
/*  }*/
/*  h6{*/
/*    font-size: 18px !important;*/
/*  }*/
/*}*/
/*@media (min-width: 768px) and (max-width: 991.98px){*/
/*  h4{*/
/*    font-size: 14px !important;*/
/*  }*/
/*  h5{*/
/*    font-size: 14px !important;*/
/*  }*/
/*}*/
.text-decoration-line-through {
  text-decoration: line-through;
}
</style>
